import React, { useState, useContext, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Card,
  Empty,
  Select,
  Tag,
  Popconfirm,
  DatePicker,
  Skeleton,
  message,
} from "antd";
import { DataContext } from "../context/DataContext";

const { Option } = Select;

function JudgmentEditorCard() {
  const dataContext = useContext(DataContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [acts, setActs] = useState([]);
  const [actsAdd] = Form.useForm();

  const [caseDescRows, setCaseDescRows] = useState(4);

  const showPopconfirm = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    document.getElementById("details-submit-button").click();
  };

  const handleCancel = () => {
    setVisible(false);
    setConfirmLoading(false);
  };

  useEffect(() => {
    setActs(dataContext.judgmentRes.ACTS);
  }, [dataContext.judgmentRes]);

  const keys_to_show = [
    "APL_NAME",
    "RES_NAME",
    "ACTS",
    "DATE",
    "CASE_DESC",
    "CASE_NO",
    "COURT",
    "JUDGE_NAME",
    "FINAL_VERDICT",
    "VERDICT_TYPE",
    "PRATICE_AREAS",
    "TITLE",
  ];

  const getFields = (key, value) => {
    const children = [];
    if (key === "DATE") {
      value = new Date(value).toDateString();
    }
    const PRATICE_AREAS_opts = [
      "Administrations",
      "Arbitrations",
      "Banking",
      "Civil",
      "Company",
      "Competition",
      "Constitution",
      "Contracts",
      "Criminal",
      "Finance",
      "IBC",
      "IP",
      "Minorities",
      "Securities",
      "Taxation",
      "Other",
    ];
    const VERDICT_TYPE_opts = ["AA", "AD", "DAW", "DWO", "DWD", "DAS", "DAR"];

    if (keys_to_show.includes(key)) {
      if (key === "PRATICE_AREAS") {
        const opts = [];
        opts.push(
          PRATICE_AREAS_opts.map((item) => <Option key={item}>{item}</Option>)
        );
        children.push(
          <Col span={24} key={`editor-${key}`}>
            <Form.Item name={`${key}`} label={`${key}`}>
              <Select
                mode="multiple"
                size="medium"
                placeholder="Please select"
                defaultValue={value}
              >
                {opts}
              </Select>
            </Form.Item>
          </Col>
        );
      } else if (key === "VERDICT_TYPE") {
        const opts = [];
        opts.push(
          VERDICT_TYPE_opts.map((item) => <Option key={item}>{item}</Option>)
        );
        children.push(
          <Col span={24} key={`editor-${key}`}>
            <Form.Item name={`${key}`} label={`${key}`}>
              <Select
                size="medium"
                placeholder={value}
                // defaultValue={value}
              >
                {opts}
              </Select>
            </Form.Item>
          </Col>
        );
      } else if (key === "DATE") {
        children.push(
          <Col span={24} key={`editor-${key}`}>
            <Form.Item name={`${key}`} label={`${key}`}>
              <DatePicker placeholder={value} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        );
      } else if (key === "FINAL_VERDICT") {
        children.push(
          <Col span={24} key={`editor-${key}`}>
            <Form.Item name={key} label={key}>
              <Select size="medium" placeholder={value}>
                <Option key={value.toString()}>{value.toString()}</Option>
                <Option key={(!value).toString()}>
                  {(!!value).toString()}
                </Option>
              </Select>
            </Form.Item>
          </Col>
        );
      } else if (key === "ACTS") {
        const addItem = (e) => {
          setActs(acts.concat([e]));
        };
        const deleteItem = (e) => {
          const arr = acts;
          arr.splice(arr.indexOf(e), 1);
          setActs(arr);
        };
        const setValueInsideInput = (e) => {
          actsAdd.setFieldsValue({
            ACTS_ADD: e,
          });
        };
        const tagRender = (props) => {
          const { label, closable } = props;

          return (
            <Tag
              closable={closable}
              onClose={() => deleteItem(label)}
              onClick={() => setValueInsideInput(label)}
              style={{ margin: 3 }}
            >
              {label}
            </Tag>
          );
        };
        children.push(
          <Col span={24} style={{ marginBottom: 30 }} key={`editor-${key}`}>
            <Form.Item name="ACTS" label="ACTS">
              <Select
                mode="multiple"
                size="medium"
                placeholder="Please select"
                value={acts}
                tagRender={tagRender}
              />
              <Form form={actsAdd}>
                <Form.Item name="ACTS_ADD">
                  <Input.Search
                    placeholder="Input Element To Add"
                    allowClear
                    enterButton="Add Item"
                    size="medium"
                    onSearch={addItem}
                  />
                </Form.Item>
              </Form>
            </Form.Item>
          </Col>
        );
      } else if (key === "CASE_DESC") {
        children.push(
          <Col span={24} key={`editor-${key}`}>
            <Form.Item name={key} label={key}>
              <Input.TextArea
                placeholder={value}
                autoSize={{ maxRows: caseDescRows }}
                onFocus={() => {
                  form.setFieldsValue({
                    [key]: value,
                  });
                  setCaseDescRows(40);
                }}
              />
            </Form.Item>
          </Col>
        );
      } else {
        children.push(
          <Col span={24} key={`editor-${key}`}>
            <Form.Item name={`${key}`} label={`${key}`}>
              <Input
                placeholder={value}
                onFocus={() =>
                  form.setFieldsValue({
                    [key]: value,
                  })
                }
              />
            </Form.Item>
          </Col>
        );
      }
    }
    return children;
  };
  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 20,
    },
  };

  const sendData = async (data) => {
    const base = "https://api.legalmind.co.in/dataFixer/update_case/byLMID";
    const query = `?lmid=${data.LMID}`;
    const response = await fetch(base + query, {
      method: "POST",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${dataContext.accessToken.access_token}`,
      },
      body: JSON.stringify(data),
    });
    if (response.status !== 200) {
      throw new Error("cannot fetch the data");
    } else {
      message.success("Data Sent for review. Please enter a new LMID.");
      dataContext.setJudgmentRes({});
      dataContext.setIsFetching(false);
      setVisible(false);
      setConfirmLoading(false);
      dataContext.setLmid("");
    }
  };

  const onFinish = (values) => {
    const data = {};
    for (const [key, value] of Object.entries(values)) {
      if (typeof value !== "undefined") {
        data[key] = value;
        if (key === "JUDGE_NAME") {
          const arr = value.split(",");
          data[key] = arr;
        }
      }
    }
    data["ACTS"] = acts;
    if (Object.keys(data).length > 0) {
      data["LMID"] = dataContext.judgmentRes.LMID;
      data["access_token"] = dataContext.accessToken.access_token;
    }
    if (Object.keys(data).includes("DATE")) {
      data["DATE"] = data["DATE"].format();
    }
    form.resetFields();
    sendData(data);
  };

  return (
    <>
      <Card
        style={{
          marginTop: 16,
          height: "100vh",
          overflow: "scroll",
          overflowX: "hidden",
          textAlign: "left",
          padding: "10px",
          width: "100%",
        }}
      >
        <Form {...layout} form={form} onFinish={onFinish}>
          {Object.keys(dataContext.judgmentRes).length !== 0 ? (
            <>
              <Row
                align="center"
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  paddingBottom: "20px",
                }}
              >
                LMID: {dataContext.judgmentRes.LMID}
              </Row>
              <Row gutter={24}>
                {Object.entries(dataContext.judgmentRes).map((item) => {
                  if (item[0] === "ACTS" && item[1] !== null) {
                    return getFields(item[0].toString(), item[1]);
                  } else if (item[1] !== null) {
                    return getFields(item[0].toString(), item[1].toString());
                  }
                })}
              </Row>
              <Row>
                <Col
                  span={24}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Button
                    id="details-submit-button"
                    type="primary"
                    htmlType="submit"
                    style={{ display: "none" }}
                  >
                    Submit
                  </Button>
                  <Button
                    style={{
                      margin: "0 8px",
                    }}
                    onClick={() => {
                      form.resetFields();
                    }}
                  >
                    Reset
                  </Button>
                  <Popconfirm
                    title={
                      <span>
                        Submit only if you're very sure.
                        <br />
                        Are you sure about making these changes?
                      </span>
                    }
                    visible={visible}
                    onConfirm={handleOk}
                    okButtonProps={{ loading: confirmLoading }}
                    onCancel={handleCancel}
                    okText="Yes"
                    cancelText="No, Let me check again!"
                  >
                    <Button type="primary" onClick={showPopconfirm}>
                      Submit
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            </>
          ) : dataContext.isFetching ? (
            <Skeleton active />
          ) : (
            <Empty
              description={
                <span>
                  No Data
                  <br />
                  Request Judgment using LMID
                </span>
              }
            ></Empty>
          )}
        </Form>
      </Card>
    </>
  );
}

export default JudgmentEditorCard;
