import React, { useState, useContext } from "react";
import { Col, Grid, Tag, Input, Row, message } from "antd";
import { DataContext } from "../../context/DataContext";
import { ArrowRightOutlined } from "@ant-design/icons";

import "./PageTitle.css";

const { Search } = Input;
const { useBreakpoint } = Grid;

const PageTitle = () => {
  const [state, setState] = useState({
    APL_NAME: "",
    RES_NAME: "",
  });
  const screenSize = useBreakpoint();
  const dataContext = useContext(DataContext);
  let lmid;

  const fetchJudgmentData = async (lmid) => {
    const base = "https://api.legalmind.co.in/dataFixer/get_case/byLMID";
    const query = `?lmid=${lmid}`;
    dataContext.setIsFetching(true);
    const response = await fetch(base + query, {
      method: "POST",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${dataContext.accessToken.access_token}`,
      },
    });
    if (response.status !== 200) {
      dataContext.setIsFetching(false);
      throw new Error("cannot fetch the data");
    }
    const data = await response.json();
    dataContext.setJudgmentRes(data);
    return data;
  };

  const updatelmid = (lmidNew) => {
    lmid = lmidNew.trim();
    if (lmid.length > 0) {
      fetchJudgmentData(lmid)
        .then((data) => {
          setState({
            APL_NAME: data.APL_NAME,
            RES_NAME: data.RES_NAME,
          });
        })
        .catch((err) => console.log(err.message));
    } else {
      message.warning("Please enter a valid LMID");
    }
  };

  return (
    <>
      <div className="page-title-container">
        <div className="page-title-text">
          {Object.keys(dataContext.judgmentRes).length !== 0 ? (
            <div style={{ fontSize: "16px", fontWeight: "600" }}>
              {state.APL_NAME + " vs. " + state.RES_NAME}{" "}
              <Tag color="blue" style={{ marginLeft: "8px" }}>
                {dataContext.isEdit ? "Edit" : "Review"}
              </Tag>
            </div>
          ) : (
            <div
              style={{
                fontSize: "16px",
                fontWeight: "600",
                textAlign: "right",
              }}
            >
              To fetch judgment data request by LMID{" "}
              <ArrowRightOutlined style={{ marginLeft: "20px" }} />
            </div>
          )}
        </div>
        <div className="page-title-searchbar">
          <Search
            id="lmidInput"
            allowClear
            value={dataContext.lmid}
            onChange={(e) => dataContext.setLmid(e.target.value)}
            onSearch={updatelmid}
            placeholder="Enter LMID"
            enterButton="Request Judgment"
          />
        </div>
      </div>
    </>
  );
};

export default PageTitle;
