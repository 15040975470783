import React, { useContext } from "react";
import { Form, Input, Button, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { DataContext } from "../context/DataContext";

const LoginModal = () => {
  const dataContext = useContext(DataContext);

  const verifyLogin = async (user, pass) => {
    const url = "https://api.legalmind.co.in/dataFixer/token";
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `username=${user}&password=${pass}`,
    });
    if (response.status !== 200) {
      message.error('Wrong Email/Password');
      throw new Error("cannot fetch the data");
    }
    const data = await response.json();
    return data;
  };

  const onFinish = (values) => {
    if (values.username.length > 0 && values.password.length > 0) {
      verifyLogin(values.username, values.password)
        .then((data) => {
          dataContext.setAccessToken(data);
          dataContext.setIsFetching(false);
        })
        .catch((err) => console.log(err.message));
    }
  };
  return (
    <div style={{ height: "80vh" }}>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        style={{ padding: "30vh 30vw" }}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your Username!",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginModal;
