import React, { useState } from "react";
import "./App.css";
import {
  Layout,
  Button,
  Tooltip,
  Row,
  Grid,
  Col,
  Switch,
  Popover,
  Statistic,
  Rate,
} from "antd";
import PageTitle from "./components/PageTitle/PageTitle";
import JudgmentCard from "./components/JudgmentCard";
import JudgmentEditorCard from "./components/JudgmentEditorCard";
import {
  CopyrightOutlined,
  LogoutOutlined,
  BulbOutlined,
  RocketOutlined,
  FireOutlined,
  ThunderboltOutlined,
  SmileOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { DataContext } from "./context/DataContext";
import LoginModal from "./components/LoginModal";

const { Header, Footer, Content } = Layout;
const { useBreakpoint } = Grid;
function App() {
  const screenSize = useBreakpoint();

  const [judgmentRes, setJudgmentRes] = useState({});
  const [accessToken, setAccessToken] = useState({});
  const [isFetching, setIsFetching] = useState({});
  const [lmid, setLmid] = useState("");

  const docEditedIcon = () => {
    const count = accessToken.doc_edited;
    if (count >= 0 && count < 20) return { icon: <SmileOutlined />, rate: 1 };
    if (count >= 20 && count < 50) return { icon: <BulbOutlined />, rate: 2 };
    if (count >= 50 && count < 90) return { icon: <ThunderboltOutlined />, rate: 3 };
    if (count >= 90 && count < 150) return { icon: <FireOutlined />, rate: 4 };
    if (count >= 150) return { icon: <RocketOutlined />, rate: 5 };
  };
  const customRatingIcons = {
    1: <><SmileOutlined style={{ fontSize: '24px' }} /><br />Lv.1</>,
    2: <><BulbOutlined style={{ fontSize: '24px' }} /><br />Lv.2</>,
    3: <><ThunderboltOutlined style={{ fontSize: '24px' }} /><br />Lv.3</>,
    4: <><FireOutlined style={{ fontSize: '24px' }} /><br />Lv.4</>,
    5: <><RocketOutlined style={{ fontSize: '24px' }} /><br />Lv.5</>
  };

  const infoRating = (
    <Rate
      defaultValue={docEditedIcon() && docEditedIcon().rate}
      character={({ index }) => customRatingIcons[index + 1]}
      style={{ margin: "auto" }}
      disabled
    />
  );

  const namePopOverContent = (
    <div>
      <Statistic
        title="Documents Edited"
        prefix={<>{docEditedIcon() && docEditedIcon().icon} &nbsp;</>}
        value={accessToken.doc_edited}
      />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Popover content={infoRating} title="Correct more docs to level up!">
          <InfoCircleOutlined />
        </Popover>
      </div>
    </div>
  );
  const [isEdit, setIsEdit] = useState(true);

  const logoutUser = () => {
    setAccessToken({});
    setJudgmentRes({});
    setIsFetching({});
  };
  return (
    <div className="App">
      <DataContext.Provider
        value={{
          judgmentRes,
          setJudgmentRes,
          accessToken,
          setAccessToken,
          isFetching,
          setIsFetching,
          lmid,
          setLmid,
          isEdit,
        }}
      >
        <Layout>
          <Header style={{ padding: "0px 20px" }} className="header">
            <div className="logo">
              <img
                src="https://d33wubrfki0l68.cloudfront.net/30568f290d29d4a4933ee2d9ae9423e469b3bf69/fc97f/assets/images/logo.png"
                alt="LegalMind Logo"
              />
            </div>
            <div className="header-right-container">
              {typeof accessToken.access_token !== "undefined" ? (
                <>
                  <Switch
                    style={{ background: "#1890ff", marginRight: "2vw" }}
                    unCheckedChildren="Review"
                    checkedChildren="Edit"
                    disabled={!accessToken.is_approver}
                    onChange={(e) => {
                      setJudgmentRes({});
                      setLmid("");
                      setIsFetching(false);
                      setIsEdit(e);
                    }}
                    defaultChecked
                  />
                  Hi,{" "}
                  <Popover content={namePopOverContent}>
                    {accessToken.full_name}
                  </Popover>
                  <Tooltip title="LogOut">
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<LogoutOutlined />}
                      style={{ marginLeft: "20px" }}
                      onClick={logoutUser}
                    />
                  </Tooltip>
                </>
              ) : (
                  `Login`
                )}
            </div>
          </Header>
          {typeof accessToken.access_token === "undefined" ? (
            <LoginModal />
          ) : (
              <Content>
                {!screenSize.md ? (
                  <>
                    <Row>
                      <Col span={24}>
                        <PageTitle />
                      </Col>
                    </Row>
                    <Row>
                      <JudgmentCard />
                    </Row>
                    <Row>
                      <JudgmentEditorCard />
                    </Row>
                  </>
                ) : (
                    <>
                      <Row>
                        <Col span={24}>
                          <PageTitle />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12} style={{ padding: "10px" }}>
                          <JudgmentCard />
                        </Col>
                        <Col span={12} style={{ padding: "10px" }}>
                          <JudgmentEditorCard />
                        </Col>
                      </Row>
                    </>
                  )}
              </Content>
            )}
          <Footer style={{ textAlign: "center" }}>
            <CopyrightOutlined /> FindMind Analytics Pvt. Ltd.
          </Footer>
        </Layout>
      </DataContext.Provider>
    </div>
  );
}

export default App;
