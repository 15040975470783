import React, { useContext } from "react";
import { Card, Row, Col, Empty, Statistic, Skeleton } from "antd";
import { DataContext } from "../context/DataContext";

const JudgmentCard = () => {
  const dataContext = useContext(DataContext);

  const getJudgment = (data) => {
    const judgment = (
      <>
        <Row style={{ paddingBottom: "20px" }}>
          <Col span={12} style={{ fontSize: "14px", fontWeight: "600" }}>
            {data.COURT}
          </Col>
          <Col
            span={12}
            style={{ fontSize: "14px", fontWeight: "600", textAlign: "right" }}
          >
            {new Date(data.DATE).toDateString()}
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            style={{ fontSize: "16px", fontWeight: "700", textAlign: "center" }}
          >
            {data.TITLE}
          </Col>
        </Row>
        <Row style={{ paddingBottom: "20px" }}>
          <Col
            span={24}
            style={{ fontSize: "12px", textAlign: "center", color: "#616161" }}
          >
            {data.CASE_NO}
          </Col>
        </Row>
        <Row gutter={16} style={{ paddingBottom: "20px" }}>
          <Col span={8} align="center">
            <Statistic
              title="Final Verdict"
              value={data.FINAL_VERDICT}
              valueStyle={{ fontSize: "14px" }}
            />
          </Col>
          <Col span={8} align="center">
            <Statistic
              title="Verdict Type"
              value={data.VERDICT_TYPE}
              valueStyle={{ fontSize: "14px" }}
            />
          </Col>
          <Col span={8} align="center">
            <Statistic
              title="Practice Areas"
              value={data.PRATICE_AREAS.join()}
              valueStyle={{ fontSize: "14px" }}
            />
          </Col>
        </Row>
        <Row style={{ paddingBottom: "40px" }}>
          <Col span={24} style={{ fontSize: "12px", fontWeight: "600" }}>
            CORAM: {data.JUDGE_NAME.join(", ")}
          </Col>
        </Row>
        <Row>
          <div
            style={{ textAlign: "justify" }}
            dangerouslySetInnerHTML={{
              __html: data.CASE_DESC.replaceAll("\n\n\n", "<br/>").replaceAll(
                "\n",
                "<br/>"
              ),
            }}
          ></div>
        </Row>
      </>
    );

    return judgment;
  };
  return (
    <>
      <Card
        style={{
          marginTop: 16,
          height: "100vh",
          overflow: "scroll",
          TypographyAlign: "left",
          padding: "10px",
          width: "100%",
        }}
      >
        {Object.keys(dataContext.judgmentRes).length !== 0 ? (
          getJudgment(dataContext.judgmentRes)
        ) : dataContext.isFetching ? (
          <Skeleton active />
        ) : (
          <Empty
            description={
              <span>
                No Data
                <br />
                Request Judgment using LMID
              </span>
            }
          ></Empty>
        )}
      </Card>
    </>
  );
};

export default JudgmentCard;
